define("llis/utils/facet-helper", ["exports"], function (exports) {
  function baseDate() {
    return {
      "aggs": {
        "lesson_date": {
          "filter": {},
          "aggs": {
            "years": {
              "date_range": {
                "field": "lesson_date",
                "format": "YYYY",
                "ranges": [{ "from": "1960", "to": "now" }]
              }
            }
          }
        }
      }
    };
  }

  function buildYear(filter) {
    var array = [{ key: '1970s', from: '1970', to: '1980' }, { key: '1980s', from: '1980', to: '1990' }, { key: '1990s', from: '1990', to: '2000' }, { key: '2000-2003', from: '2000', to: '2004' }, { key: '2004', from: '2004', to: '2005' }, { key: '2005', from: '2005', to: '2006' }, { key: '2006', from: '2006', to: '2007' }, { key: '2007', from: '2007', to: '2008' }, { key: '2008', from: '2008', to: '2009' }, { key: '2009', from: '2009', to: '2010' }, { key: '2010', from: '2010', to: '2011' }, { key: '2011', from: '2011', to: '2012' }, { key: '2012', from: '2012', to: '2013' }, { key: '2013', from: '2013', to: '2014' }, { key: '2014', from: '2014', to: '2015' }, { key: '2015', from: '2015', to: '2016' }, { key: '2016', from: '2016', to: '2017' }, { key: '2017', from: '2017', to: '2018' }, { key: '2018', from: '2018', to: '2019' }];

    var wrapper = {};

    for (var i = 0; i < array.length; i++) {
      var range = array[i];
      var _obj = _makeRange();

      _obj.date_range.ranges.push({
        from: array[i].from,
        to: array[i].to
      });

      wrapper[range.title] = _obj;
      wrapper['filter'] = filter;
    };

    return wrapper;

    function _makeRange() {
      return {
        "date_range": {
          "field": "lesson_date",
          "format": "YYYY",
          "ranges": []
        }
      };
    }
  }

  //function yearAggs(){
  //	return {
  //    "aggs": {
  //      "1970s": {
  //        "date_range": {
  //          "field": "lesson_date",
  //          "format": "YYYY",
  //          "ranges": [ { "from": "1970", "to": "1980" } ]
  //        }
  //      },
  //      "1980s": {
  //        "date_range": {
  //          "field": "lesson_date",
  //          "format": "YYYY",
  //          "ranges": [ { "from": "1980", "to": "1990" } ]
  //        }
  //      },
  //      "1990s": {
  //        "date_range": {
  //          "field": "lesson_date",
  //          "format": "YYYY",
  //          "ranges": [ { "from": "1990", "to": "2000" } ]
  //        }
  //      },
  //      "2000-2003": {
  //        "date_range": {
  //          "field": "lesson_date",
  //          "format": "YYYY",
  //          "ranges": [ { "from": "2000", "to": "2004" } ]
  //        }
  //      },
  //      "2004": {
  //        "date_range": {
  //          "field": "lesson_date",
  //          "format": "YYYY",
  //          "ranges": [ { "from": "2004", "to": "2005" } ]
  //        }
  //      }
  //      "2005": {
  //        "date_range": {
  //          "field": "lesson_date",
  //          "format": "YYYY",
  //          "ranges": [ { "from": "2004", "to": "2005" } ]
  //        }
  //      }
  //      "2006": {
  //        "date_range": {
  //          "field": "lesson_date",
  //          "format": "YYYY",
  //          "ranges": [ { "from": "2004", "to": "2005" } ]
  //        }
  //      }
  //      "2007": {
  //        "date_range": {
  //          "field": "lesson_date",
  //          "format": "YYYY",
  //          "ranges": [ { "from": "2004", "to": "2005" } ]
  //        }
  //      }
  //      "2008": {
  //        "date_range": {
  //          "field": "lesson_date",
  //          "format": "YYYY",
  //          "ranges": [ { "from": "2004", "to": "2005" } ]
  //        }
  //      }
  //      "2009": {
  //        "date_range": {
  //          "field": "lesson_date",
  //          "format": "YYYY",
  //          "ranges": [ { "from": "2004", "to": "2005" } ]
  //        }
  //      }
  //      "2004": {
  //        "date_range": {
  //          "field": "lesson_date",
  //          "format": "YYYY",
  //          "ranges": [ { "from": "2004", "to": "2005" } ]
  //        }
  //      }
  //      "2004": {
  //        "date_range": {
  //          "field": "lesson_date",
  //          "format": "YYYY",
  //          "ranges": [ { "from": "2004", "to": "2005" } ]
  //        }
  //      }
  //      "2004": {
  //        "date_range": {
  //          "field": "lesson_date",
  //          "format": "YYYY",
  //          "ranges": [ { "from": "2004", "to": "2005" } ]
  //        }
  //      }
  //      "2004": {
  //        "date_range": {
  //          "field": "lesson_date",
  //          "format": "YYYY",
  //          "ranges": [ { "from": "2004", "to": "2005" } ]
  //        }
  //      }
  //      "2004": {
  //        "date_range": {
  //          "field": "lesson_date",
  //          "format": "YYYY",
  //          "ranges": [ { "from": "2004", "to": "2005" } ]
  //        }
  //      }
  //
  //  }

  exports.baseDate = baseDate;
  exports.buildYear = buildYear;
});