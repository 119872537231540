define('llis/serializers/lesson', ['exports', 'ember-data/serializers/json-api', 'ember'], function (exports, _emberDataSerializersJsonApi, _ember) {
  exports['default'] = _emberDataSerializersJsonApi['default'].extend({

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      //console.log('[serializer][lesson]:[normalizeResponse]');

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var results = [];

      //console.log('[serializer][lesson]:[normalizeFindAllResponse]');
      if (payload.hits.hits.length) {
        results = payload.hits.hits.filter(function (resp) {
          return resp._type && typeof resp.fields !== 'undefined';
        }).map(function (r) {
          r = {
            type: r._type,
            id: r._id,
            attributes: {
              "categories": r.fields.categories || null,
              //"description-event": r.fields.description_event,
              //"driving-event": r.fields.drivingEvent,
              //"evidence": r.fields.evidence,
              //"lesson": r.fields.lesson,
              "lesson-abstract": r.fields.lessonAbstract,
              "lesson-date": r.fields.lessonDate,
              //"lesson-learned": r.fields.lesson_learned,
              //"mission-directorate": r.fields.missionDirectorate,
              "organization": r.fields.organization,
              //"program-phase": r.fields.programPhase,
              //"program-role": r.fields.programRole,
              //"program-relation": r.fields.programRelation,
              //"recommendation": r.fields.recommendation,
              "title": r.fields.title
            }
          };

          // remove 'none' from categories
          if (r.attributes.categories.indexOf('None') === 0) {
            delete r.attributes.categories;
          }
          return r;
        });

        payload = {
          data: results,
          meta: {
            total: payload.hits.total,
            total_pages: Math.ceil(payload.hits.total / 10),
            totalPages: Math.ceil(payload.hits.total / 10)
          }
        };
      }

      //console.log(payload);
      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      //console.log('[serializer][lesson]:[normalizeSingleResponse]');
      payload = {
        data: {
          id: payload._id,
          type: payload._type,
          attributes: {
            "categories": payload._source.categories,
            "attachments": payload._source.attachments,
            "description-event": payload._source.description_event,
            "driving-event": payload._source.drivingEvent,
            "evidence": payload._source.evidence,
            "lesson": payload._source.lesson,
            "lesson-abstract": payload._source.lessonAbstract,
            "lesson-date": payload._source.lesson_date,
            "lesson-learned": payload._source.lesson_learned,
            "mission-directorate": payload._source.missionDirectorate,
            "organization": payload._source.organization,
            "program-phase": payload._source.programPhase,
            "program-role": payload._source.programRole,
            "program-relation": payload._source.programRelation,
            "recommendation": payload._source.recommendation,
            "title": payload._source.title
          }
        },
        meta: {
          index: payload._index,
          exists: payload.exists,
          version: payload._version
        }
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      //console.log('[serializer][lesson]:[normalizeQueryResponse]');
      //console.log(payload);

      var aggs = this.extractAggs(payload);

      if (payload.hits.hits.length) {
        var results = payload.hits.hits.map(function (r) {
          return {
            type: r._type,
            id: r._id,
            attributes: {
              "categories": r._source.categories,
              "description-event": r._source.description_event,
              "driving-event": r._source.drivingEvent,
              "evidence": r._source.evidence,
              "lesson": r._source.lesson,
              "lesson-abstract": r._source.lessonAbstract,
              "lesson-date": r._source.lesson_date,
              "lesson-learned": r._source.lesson_learned,
              "mission-directorate": r._source.missionDirectorate,
              "organization": r._source.organization,
              "program-phase": r._source.programPhase,
              "program-role": r._source.programRole,
              "program-relation": r._source.programRelation,
              "recommendation": r._source.recommendation,
              "title": r._source.title
            }
          };
        });

        //if (false && aggs) {
        //  console.log('ifAggs');
        //  console.log(aggs.length);
        //  for (i = 0; i < aggs.length; i++) {
        //    console.log('len');
        //    results.push(aggs[i]);
        //  }
        //}

        payload = {
          data: results,
          meta: {
            total: payload.hits.total,
            batchSize: results.length,
            total_pages: Math.ceil(payload.hits.total / 30),
            aggs: aggs,
            totalPages: Math.ceil(payload.hits.total / 30)
          }
        };
      } else {
        payload = {
          data: [],
          meta: {
            total: 0,
            batchSize: 0,
            total_pages: 0,
            totalPages: 0
          }
        };
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    extractAggs: function extractAggs(payload) {
      var aggsArray = _ember['default'].A();
      var facets = payload.facets || payload.aggregations;
      var i, facet;

      //console.log('[serializer][lesson]:[extractAggs]');
      //console.log(facets);

      if (_ember['default'].isPresent(facets)) {
        if (_ember['default'].isPresent(facets.organization.agg.buckets)) {
          //aggsObject['NASA Centers'] = [];
          for (i = 0; i < facets.organization.agg.buckets.length; i++) {
            facet = facets.organization.agg.buckets[i];

            //aggsObject['NASA Centers'].push({
            aggsArray.push({
              "count": facet.doc_count,
              "isSelected": false,
              "fType": "organization",
              "term": facet.key
            });
          }
        }

        if (_ember['default'].isPresent(facets.categories.agg.buckets)) {
          //aggsObject['Topics'] = [];
          for (i = 0; i < facets.categories.agg.buckets.length; i++) {
            facet = facets.categories.agg.buckets[i];

            //aggsObject['Topics'].push({
            aggsArray.push({
              "count": facet.doc_count,
              "isSelected": false,
              "fType": "categories",
              "term": facet.key
            });
          }
        }

        if (_ember['default'].isPresent(facets.missionDirectorate.agg.buckets)) {
          //aggsObject['Mission Directorates'] = [];
          for (i = 0; i < facets.missionDirectorate.agg.buckets.length; i++) {
            facet = facets.missionDirectorate.agg.buckets[i];

            //aggsObject['Mission Directorates'].push({
            aggsArray.push({
              "count": facet.doc_count,
              "isSelected": false,
              "fType": "directorate",
              "term": facet.key
            });
          }
        }

        if (_ember['default'].isPresent(facets.lesson_date.agg.buckets)) {
          //aggsObject['Years'] = [];
          for (var key in facets.lesson_date.agg.buckets) {
            facet = facets.lesson_date.agg.buckets[key];

            //aggsObject['Years'].push({
            if (facet.doc_count) {
              aggsArray.push({
                "count": facet.doc_count,
                "fType": "lesson_date",
                "year": key,
                "term": key,
                "interval": {
                  from: facet.from_as_string,
                  to: facet.to_as_string
                }
              });
            }
          }
        }
      }
      return aggsArray;
    }
  });
});