define('llis/controllers/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    //actions: {
    //  addFacet(obj) {
    //    var { fType, value } = obj;

    //    this.transitionTo(fType, value);
    //    this.set('page', 1);
    //  }
    //},

  });
});