define('llis/routes/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    store: _ember['default'].inject.service(),

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var ArrayPromiseProxy = _ember['default'].ArrayProxy.extend(_ember['default'].PromiseProxyMixin),
          promise = this.get('store').query('lesson', {}).then(function (items) {
        return items.get('meta.aggs');
      });

      var proxy = ArrayPromiseProxy.create({ promise: promise });

      controller.set('facets', proxy);
    },
    actions: {
      submitQuery: function submitQuery(query) {
        this.transitionTo('search', { queryParams: { query: query, page: 1 } });
      },
      addFacet: function addFacet(obj) {
        var params = { queryParams: { "page": 1 } };
        var fType = obj.fType;
        var value = obj.value;

        params.queryParams[fType] = value;
        this.transitionTo('search', params);
      }
    }
  });
});