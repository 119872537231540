define('llis/components/search-list', ['exports', 'ember', 'ember-cli-pagination/computed/paged-array'], function (exports, _ember, _emberCliPaginationComputedPagedArray) {
  exports['default'] = _ember['default'].Component.extend({
    pagedContent: (0, _emberCliPaginationComputedPagedArray['default'])("results", {
      perPage: 10

    }),
    //pageBinding: "pagedContent.page",
    //totalPagesBinding: "pagedContent.totalPages",

    searchSorting: ['date:desc'],
    arrangedContent: _ember['default'].computed.sort('results', 'searchSorting')

  });
});
//actions: {
//  createPost: function() {
//    this.sendAction('createAction');
//  }
//}