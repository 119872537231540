define('llis/app', ['exports', 'ember', 'llis/resolver', 'ember-load-initializers', 'llis/config/environment', 'llis/models/custom-inflector-rules'], function (exports, _ember, _llisResolver, _emberLoadInitializers, _llisConfigEnvironment, _llisModelsCustomInflectorRules) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _llisConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _llisConfigEnvironment['default'].podModulePrefix,
    Resolver: _llisResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _llisConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});