define("llis/templates/components/list-filter", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "llis/templates/components/list-filter.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        var el2 = dom.createTextNode(" Results: ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [1, 14], [1, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "key-up", ["subexpr", "action", ["handleFilterEntry"], [], ["loc", [null, [1, 27], [1, 55]]], 0, 0], "class", "light", "placeholder", "Search Terms"], ["loc", [null, [1, 0], [1, 98]]], 0, 0], ["content", "results.meta.total", ["loc", [null, [2, 16], [2, 38]]], 0, 0, 0, 0], ["inline", "yield", [["get", "results", ["loc", [null, [3, 8], [3, 15]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 0], [3, 17]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});