define('llis/adapters/elasticsearch', ['exports', 'ember-data/adapters/json-api', 'ember', 'llis/config/environment', 'ember-network/fetch'], function (exports, _emberDataAdaptersJsonApi, _ember, _llisConfigEnvironment, _emberNetworkFetch) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  //const {get, inject} = Ember;

  exports['default'] = _emberDataAdaptersJsonApi['default'].extend({
    host: _llisConfigEnvironment['default'].DS.host,
    namespace: _llisConfigEnvironment['default'].DS.namespace,

    //urlForQuery: function(modelName) {
    //  var host, namespace, url;

    //  host = Ember.get(this, "host");
    //  namespace = Ember.get(this, "namespace");
    //  url = [];

    //  if (host) {
    //    url.push(host);
    //  }

    //  if (namespace) {
    //    url.push(namespace);
    //  }

    //  if (modelName) {
    //    url.push(modelName);
    //  }

    //  //url.push(Ember.get(this, "url"));
    //  url = url.join("/");

    //  if (!host) {
    //    url = "/" + url;
    //  }

    //  return url;
    //},

    query: function query(store, type, params) {
      var url = [this.buildURL(type.modelName), '_search'].join('/');
      var page = 0,
          size = 10,
          payload;

      //console.log('[adapter][lesson]:[query]: ' + JSON.stringify(params));

      if (params.page) {
        page = params.page - 1;
      }
      if (params.per_page) {
        size = params.per_page;
      }

      // Default Payload
      payload = {
        sort: [{ "_score": "desc" }],
        from: page * size,
        size: size,
        query: { "match_all": {} }
      };

      if (params.sort) {
        var _params$sort$split = params.sort.split('-');

        var _params$sort$split2 = _slicedToArray(_params$sort$split, 2);

        var field = _params$sort$split2[0];
        var sort = _params$sort$split2[1];

        payload.sort = [];
        payload.sort.push({});
        payload.sort[0][field] = {
          missing: "_last",
          order: sort
        };
      }

      if (params.query) {
        payload.query = { "query_string": { "query": params.query } };
      }
      if (params.aggs) {
        payload.aggs = params.aggs;
      }
      if (params.filter) {
        payload.filter = params.filter;
      }

      if (_ember['default'].isPresent(params.alt_query)) {
        payload = params.alt_query;
        //console.log('[adapter][lesson]:[query][alt_query]: ');
        //console.log(payload);
      }
      //console.log('[adapter][lesson]:[query][alt_query]: ');
      //console.log(payload);

      return (0, _emberNetworkFetch['default'])(url, {
        method: "POST",
        body: JSON.stringify(payload)
      }).then(function (resp) {
        return resp.json();
      });
    },

    findAll: function findAll(store, type) {
      var url = [this.buildURL(type.modelName), '_search'].join('/');

      var payload = JSON.stringify({
        "query": { "match_all": {} },
        "from": 0,
        "size": 16000, // @TODO: max limit of ES. should be refactored
        "fields": [
          //  'lessonAbstract',
          //  'title',
          //  'organization',
          //  'lessonDate',
          //  'categories'
        ]
      });

      return (0, _emberNetworkFetch['default'])(url, {
        method: "post",
        body: payload
      }).then(function (response) {
        var resp = response.json();
        return resp;
      });
    }
  });
});