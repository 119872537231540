define('llis/routes/search', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin'], function (exports, _ember, _emberCliPaginationRemoteRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberCliPaginationRemoteRouteMixin['default'], {
    aggs: _ember['default'].inject.service(),

    queryParams: {
      page: {
        replace: true,
        refreshModel: true
      },
      query: {
        replace: true
      },
      sort: {
        replace: true,
        refreshModel: true
      },
      organization: {
        replace: true,
        refreshModel: true
      },
      categories: {
        replace: true,
        refreshModel: true
      },
      directorate: {
        replace: true,
        refreshModel: true
      },
      lesson_date: {
        replace: true,
        refreshModel: true
      }
    },

    perPage: 30,
    directorate: null,
    categories: null,
    organization: null,
    year: null,

    model: function model(params) {
      //let host = this.get('fastboot.host');
      return this.findPaged('lesson', params);
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('total', model.get('meta.total'));
      controller.set('batchSize', model.get('meta.batchSize'));
      controller.set('facets', model.get('meta.aggs'));
    },

    actions: {
      submitQuery: function submitQuery(query) {
        var self = this;
        var searchController = this.controllerFor('search');

        searchController.set('page', 1);
        this.findPaged('lesson', { page: 1, query: query }).then(function (model) {
          searchController.set('model', model);
          self.refresh();
        });
      },

      resetQuery: function resetQuery() {
        this.transitionTo('/search');
        //var self = this;
        //var searchController = this.controllerFor('search');

        //searchController.set('page', 1);
        ////searchController.destroy('dc_subject', '');
        ////searchController.distroy('dc_organization', '');
        //searchController.set('query', '');
        //this.findPaged('lesson', {}).then(function(model) {
        //  searchController.set('model', model);
        //  self.refresh();
        //});
      },

      //addFacet(fType, value) {
      //  var fType = value.get('fType'),
      //      term = value.get('term'),
      //      params = {queryParams: {"page":1}};

      //  //this.get('aggs.' + fType).push(term);
      //  //console.log(this.get('queryParams'));
      //  //console.log(this.get('dc_organization'));
      //  this.get('aggs').addQuertermy(fType, term);
      //  params.queryParams[fType] = term;
      //  this.transitionTo('search', params);
      //},

      error: function error(_error) {
        if (_error) {
          return this.transitionTo('index');
        }
      }
    }
  });
});