define('llis/adapters/facet', ['exports', 'llis/config/environment', 'ember-network/fetch', 'llis/adapters/lesson'], function (exports, _llisConfigEnvironment, _emberNetworkFetch, _llisAdaptersLesson) {

    //const {get, inject} = Ember;

    exports['default'] = _llisAdaptersLesson['default'].extend({
        namespace: _llisConfigEnvironment['default'].DS.namespace,

        findAll: function findAll() {
            //console.log('[adapter][facet]:[findAll]');
            var url = [this.buildURL('lesson'), '_search'].join('/');
            var payload = JSON.stringify({
                "sort": [{
                    "_score": "desc"
                }],
                "query": {
                    "query_string": { "query": "test" }
                },
                "aggs": {
                    "categories": {
                        "terms": {
                            "field": "categories",
                            "all_terms": true,
                            "size": 5000
                        }
                    },
                    "directorate": {
                        "terms": {
                            "field": "missionDirectorate",
                            "all_terms": true,
                            "size": 10
                        }
                    },
                    "organization": {
                        "terms": {
                            "field": "organization",
                            "all_terms": true,
                            "size": 32
                        }
                    },
                    "lesson_date": {
                        "date_histogram": {
                            "field": "lesson_date",
                            "interval": "year"
                        }
                    }
                },
                "size": 30 //you're welcome internet
            });

            return (0, _emberNetworkFetch['default'])(url, {
                method: "POST",
                body: payload
            }).then(function (response) {
                return response.json();
            });
        }

    });
});
//import JSONAPIAdapter from 'ember-data/adapters/json-api';