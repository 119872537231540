define('llis/services/aggs', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    query: _ember['default'].A(),
    dc_organization: "",
    dc_subject: _ember['default'].A(),
    directorate: _ember['default'].A(),

    test: function test() {
      //console.log('aggs service test');

      //var noParams = this.buildQuery();

      //var temp = {};
      //temp[agg.field] = agg.value;

      //var temp2 = {"dc_relation_applicableenterprise":"Aeronautics Research"};

      //var soloParam = this.buildQuery([temp]);
      //var moroParam = this.buildQuery([temp, temp2]);

      //console.log({noParams, moroParam, soloParam});
      //console.log(JSON.stringify(noParams));
      //console.log(JSON.stringify(soloParam));
      //console.log(JSON.stringify(moroParam));
    },

    // aggs: array([{terms:[], other???}])
    buildQuery: _ember['default'].computed('dc_subject', 'dc_organization', 'directorate', function () {
      var terms = [],
          term,
          data,
          obj;

      var aggs = {};

      //console.log({subject, organization, directorate});

      if (true) {
        return true;
      }
      //console.log('buildQuery');
      if (!_ember['default'].isPresent(aggs)) {
        //console.log('no aggs!');
        return this.defaultQuery();
      } else if (aggs && aggs.length === 1) {
        for (var i in aggs[0]) {
          term = this.buildTerm(i, aggs[0][i]);
        }
        data = this.termData();

        obj = {
          "filter": term,
          "sort": [{ "_score": "desc" }],
          "aggs": data
        };

        for (i in obj.aggs) {
          obj.aggs[i]['filter'] = term;
        }

        return obj;
      } else if (aggs && aggs.length > 1) {
        terms = [];
        data = this.termData();
        var defQuery = this.defaultQuery();

        //console.log(aggs);
        for (var j = 0; j < aggs.length; j++) {
          for (i in aggs[j]) {
            terms.push(this.buildTerm(i, aggs[j][i]));
          }
        }
        var filtered = this.buildBoolFilter(terms);
        for (i in data) {
          data[i]['filter'] = filtered;
        }
        return { "aggs": data, "sort": defQuery.sort };
      }
      //   terms = [term, term2];
      //   filter = this.encapObj(term, "filter");
    }),

    addQuery: function addQuery(type, term) {
      var multiSelect = this.get('types').filterBy('fType', type).get('firstObject.multiSelect');

      //console.log(type + ' ' + multiSelect);
      if (multiSelect) {
        //this.get(type).push(term);
      } else {
          this.set(type, term);
        }
    },

    defaultQuery: function defaultQuery() {
      return {
        "sort": [{ "_score": "desc" }],
        "aggs": {
          "dc_subject": {
            "terms": {
              "field": "dc_subject",
              "all_terms": true,
              "size": 128
            }
          },
          "directorate": {
            "terms": {
              "field": "dc_relation_applicableenterprise",
              "all_terms": true,
              "size": 10
            }
          },
          "dc_organization": {
            "terms": {
              "field": "dc_organization",
              "all_terms": true,
              "size": 32
            }
          },
          "lesson_date": {
            "date_histogram": {
              "field": "lesson_date",
              "interval": "year"
            }
          }
        },
        "size": 0
      };
    },

    // { term : { dc_org : afrc } }
    buildTerm: function buildTerm(field, value) {
      //console.log({field, value});
      //console.log({'field': field, "v": value});
      var obj = { "term": {} };

      obj.term[field] = value;

      return obj;
    },

    //for use with filter?
    encapObj: function encapObj(obj, property) {
      var prop = {};
      prop[property] = obj;
      return prop;
    },

    buildBoolFilter: function buildBoolFilter(termsArray) {
      var obj = { "bool": { "must": [] } };
      //console.log(termsArray.length);
      for (var i = 0; i < termsArray.length; i++) {
        obj.bool.must.push(termsArray[i]);
      }
      return obj;
    },

    types: [{
      'fType': 'dc_organization',
      'multiSelect': 'false',
      'title': 'NASA Centers',
      'query': 'center',
      'weight': 0
    }, {
      'fType': 'dc_subject',
      'multiSelect': 'true',
      'title': 'Topics',
      'query': 'subject',
      'weight': 0
    }, {
      'fType': 'directorate',
      'multiSelect': 'true',
      'title': 'Mission Directorates',
      'query': 'directorate',
      'weight': 0
    }, {
      'fType': 'lesson_date',
      'multiSelect': 'false',
      'title': 'By Year',
      'query': 'year',
      'weight': 0
    }],

    centerMap: {
      "afrc": "Armstrong Flight Research Center",
      "arc": "Ames Research Center",
      "grc": "Glenn Research Center",
      "gsfc": "Goddard Space Flight Center",
      "hq": "NASA Headquarters",
      "ivv": "NASA IVV",
      "jpl": "Jet Propulsion Laboratory",
      "jsc": "Johnson Space Center",
      "ksc": "Kennedy Space Center",
      "larc": "Langley Research Center",
      "msfc": "Marshall Space Flight Center",
      "nesc": "NASA Engineering & Safety Center",
      "ssc": "Stennis Space Center",
      "wff": "Wallups Flight Facility",
      "wstf": "White Sands Test Facility"
    },

    _getRecord: function _getRecord(type, id) {
      var store = this.get('store'),
          record;

      record = store.peekRecord(type, id);

      if (_ember['default'].isPresent(record)) {
        return this.promisify(record);
      } else {
        return store.findRecord(type, id);
      }
    },

    getLesson: function getLesson(type, id) {
      var self = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        self._getRecord(type, id).then(function (_record) {
          var likeThisFetched = _record.get('likeThisFetched');
          if (likeThisFetched) {
            resolve(_record);
          } else {
            self.promisify(self.getMlt(id)).then(function (mlt) {
              _record.set('mlt', mlt);
              _record.set('likeThisFetched', true);
              resolve(_record);
            });
          }
        });
      });
    },

    getMlt: function getMlt(id) {
      var self = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        self.get('store').query('lesson', {
          "alt_query": {
            "query": {
              "more_like_this": {
                "include": false,
                "like": [{ "_index": "llis", "_id": id }]
              }
            }
          }
        }).then(function (mlt) {
          resolve(mlt);
        });
      });
    },

    promisify: function promisify(item) {
      return new _ember['default'].RSVP.Promise(function (resolve) {
        resolve(item);
      });
    },

    termData: function termData() {
      return {
        "dc_subject": {
          "aggs": {
            "agg": {
              "terms": {
                "field": "dc_subject",
                "all_terms": false,
                "size": 128
              }
            }
          }
        },
        "directorate": {
          "aggs": {
            "agg": {
              "terms": {
                "field": "dc_relation_applicableenterprise",
                "all_terms": false,
                "size": 10
              }
            }
          }
        },
        "dc_organization": {
          "aggs": {
            "agg": {
              "terms": {
                "field": "dc_organization",
                "all_terms": false,
                "size": 32
              }
            }
          }
        },
        "lesson_date": { //just add filter
          "aggs": {
            "agg": {
              "date_histogram": {
                "field": "lesson_date",
                "interval": "year"
              }
            }
          }
        }
      };
    }

  });
});