define('llis/adapters/lesson', ['exports', 'ember', 'ember-network/fetch', 'llis/adapters/elasticsearch', 'ember-es-adapter/utils/es-query-builder', 'llis/utils/facet-helper'], function (exports, _ember, _emberNetworkFetch, _llisAdaptersElasticsearch, _emberEsAdapterUtilsEsQueryBuilder, _llisUtilsFacetHelper) {

  //const {get, inject} = Ember;

  exports['default'] = _llisAdaptersElasticsearch['default'].extend({
    aggs: [{
      type: 'term',
      name: 'categories',
      field: "categories",
      kind: 'terms',
      size: 128
    }, {
      type: 'term',
      name: 'directorate',
      field: "missionDirectorate",
      kind: 'terms',
      size: 10
    }, {
      type: 'term',
      name: 'organization',
      field: "organization",
      kind: 'terms',
      size: 32
    }, {
      type: 'date',
      name: 'lesson_date',
      kind: 'date_range',
      field: "lesson_date",
      interval: "year",
      date_range: {
        field: 'lesson_date',
        format: 'YYYY',
        ranges: [{ from: "1960", to: "now" }]
      }
    }],

    query: function query(store, type, params) {

      params['filter'] = this.buildFilterObjects(params);
      params['aggs'] = this.buildAggObject(params);
      //console.log('[adapter][lesson]:[query]: ' + JSON.stringify(params));
      //console.log(filterObjects);

      // Do default actions and submit query.
      return this._super(store, type, params);
    },

    buildAggObject: function buildAggObject(params) {
      var aggs = this.get('aggs'),
          self = this;

      var aggItems = aggs.map(function (agg) {
        var obj = {};
        var tmp = '.name';

        obj[agg.field] = {
          filter: self.buildFilterObjects(params), // "clone" if you know what i mean
          aggs: { agg: {} }
        };

        if (agg.field == "organization") {
          tmp = '.abr';
        }
        var filterParams = {
          field: agg.field + tmp,
          all_terms: true,
          size: agg.size
        };

        obj[agg.field].aggs.agg[agg.kind] = filterParams;

        if (agg.type === 'date') {
          obj[agg.field]['aggs'] = {
            agg: {}
          };
          filterParams = agg.date_range;
          obj[agg.field].aggs.agg[agg.kind] = {
            field: 'lesson_date',
            format: 'YYYY',
            keyed: true,
            ranges: [{ key: '1970s', from: '1970', to: '1980' }, { key: '1980s', from: '1980', to: '1990' }, { key: '1990s', from: '1990', to: '2000' }, { key: '2000-2003', from: '2000', to: '2004' }, { key: '2004', from: '2004', to: '2005' }, { key: '2005', from: '2005', to: '2006' }, { key: '2006', from: '2006', to: '2007' }, { key: '2007', from: '2007', to: '2008' }, { key: '2008', from: '2008', to: '2009' }, { key: '2009', from: '2009', to: '2010' }, { key: '2010', from: '2010', to: '2011' }, { key: '2011', from: '2011', to: '2012' }, { key: '2012', from: '2012', to: '2013' }, { key: '2013', from: '2013', to: '2014' }, { key: '2014', from: '2014', to: '2015' }, { key: '2015', from: '2015', to: '2016' }, { key: '2016', from: '2016', to: '2017' }, { key: '2017', from: '2017', to: '2018' }, { key: '2018', from: '2018', to: '2019' }, { key: '2019', from: '2019', to: '2020' }, { key: '2020', from: '2020', to: '2021' }, { key: '2021', from: '2021', to: '2022' }, { key: '2022', from: '2022', to: '2023' }, { key: '2023', from: '2023', to: '2024' }]
          };
        }

        return obj;
      }).reduce(function (a, b) {
        for (var attrname in b) {
          a[attrname] = b[attrname];
        }
        return a;
      });

      return aggItems;
    },

    buildFilterObjects: function buildFilterObjects(params) {
      var aggs = this.get('aggs'),
          self = this;

      var termsObj = aggs.filter(function (agg) {
        // filter out aggs that don't have params
        return _ember['default'].isPresent(params[agg.name]);
      }).map(function (agg) {
        // build arrays of all search params
        return params[agg.name].split('+').map(function (param) {
          agg['value'] = param;
          return JSON.parse(JSON.stringify(agg)); // "clone" if you know what i mean
        });
      }).reduce(function (a, b) {
        return a.concat(b);
      }, []).map(function (item) {
        var fn = 'build-' + item.type;
        return self[fn.camelize()](item);
      });

      return this.buildBoolFilter(termsObj);
    },

    buildTerm: function buildTerm(item) {
      var field = item.field;
      var value = item.value;
      var obj = { term: {} };
      //console.log({field, value});
      //console.log({'field': field, "v": value});

      var tmp = '.name';
      if (field == 'organization') tmp = '.abr';
      obj.term[field + tmp] = value;

      return obj;
    },

    buildDate: function buildDate(item) {
      var field = item.field;
      var value = item.value;
      var obj = { range: {} };

      obj.range[field] = {};
      //console.log({field, value});
      //console.log({'field': field, "v": value});
      var ranges = [{ key: '1970s', gte: '1970', lt: '1980' }, { key: '1980s', gte: '1980', lt: '1990' }, { key: '1990s', gte: '1990', lt: '2000' }, { key: '2000-2003', gte: '2000', lt: '2004' }, { key: '2004', gte: '2004', lt: '2005' }, { key: '2005', gte: '2005', lt: '2006' }, { key: '2006', gte: '2006', lt: '2007' }, { key: '2007', gte: '2007', lt: '2008' }, { key: '2008', gte: '2008', lt: '2009' }, { key: '2009', gte: '2009', lt: '2010' }, { key: '2010', gte: '2010', lt: '2011' }, { key: '2011', gte: '2011', lt: '2012' }, { key: '2012', gte: '2012', lt: '2013' }, { key: '2013', gte: '2013', lt: '2014' }, { key: '2014', gte: '2014', lt: '2015' }, { key: '2015', gte: '2015', lt: '2016' }, { key: '2016', gte: '2016', lt: '2017' }, { key: '2017', gte: '2017', lt: '2018' }, { key: '2018', gte: '2018', lt: '2019' }, { key: '2019', gte: '2019', lt: '2020' }, { key: '2020', gte: '2020', lt: '2021' }, { key: '2021', gte: '2021', lt: '2022' }, { key: '2022', gte: '2022', lt: '2023' }, { key: '2023', gte: '2023', lt: '2024' }].findBy('key', item.value);

      //console.log(ranges);
      obj.range[field] = { "format": "YYYY", gte: ranges.gte, lt: ranges.lt };

      return obj;
    },

    buildBoolFilter: function buildBoolFilter(termsArray) {
      var obj = { "bool": { "must": [] } };
      //console.log(termsArray.length);
      for (var i = 0; i < termsArray.length; i++) {
        obj.bool.must.push(termsArray[i]);
      }

      if (!termsArray.length) {
        //console.log('no termsArray');
        obj.bool.must.push({ 'match_all': {} });
      }
      return obj;
    },

    findAll: function findAll(store, type) {
      var url = [this.buildURL(type.modelName), '_search'].join('/');

      var payload = JSON.stringify({
        "query": { "match_all": {} },
        "from": 0,
        "size": 16000, // @TODO: max limit of ES. should be refactored
        "fields": [
          //  'lessonAbstract',
          //  'title',
          //  'organization',
          //  'lessonDate',
          //  'categories'
        ]
      });

      return (0, _emberNetworkFetch['default'])(url, {
        method: "post",
        body: payload
      }).then(function (response) {
        var resp = response.json();
        return resp;
      });
    }
  });
});