define('llis/router', ['exports', 'ember', 'llis/config/environment'], function (exports, _ember, _llisConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _llisConfigEnvironment['default'].locationType
  });

  exports['default'] = Router.map(function () {
    this.route('lesson', { path: '/lesson' }, function () {
      this.route('index', { path: '/:lesson_id' });
      this.route('error');
      this.route('loading');
    });
    this.route('search');
    this.route('home');
    this.route('error', { path: '/*page' });
  });
  exports['default'] = Router;
});