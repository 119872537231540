define('llis/serializers/facet', ['exports', 'ember', 'ember-data/serializers/json-api'], function (exports, _ember, _emberDataSerializersJsonApi) {
  exports['default'] = _emberDataSerializersJsonApi['default'].extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      //console.log('[serializer][facet]:[normalizeResponse]');

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var facets = payload.facets || payload.aggregations;
      var results = [];
      var i, facet;

      //console.log('[serializer][facet]:[normalizeFindAllResponse]');
      //console.log(facets);
      if (_ember['default'].isPresent(facets)) {
        if (_ember['default'].isPresent(facets.dc_organization.buckets)) {
          for (i = 0; i < facets.dc_organization.buckets.length; i++) {
            facet = facets.dc_organization.buckets[i];

            results.push({
              "id": i + '-organization',
              "type": "facet",
              "attributes": {
                "count": facet.doc_count,
                "f-type": "dc_organization",
                "term": facet.key
              }
            });
          }
        }

        if (_ember['default'].isPresent(facets.dc_subject.buckets)) {
          for (i = 0; i < facets.dc_subject.buckets.length; i++) {
            facet = facets.dc_subject.buckets[i];

            results.push({
              "id": i + '-subject',
              "type": "facet",
              "attributes": {
                "count": facet.doc_count,
                "f-type": "dc_subject",
                "term": facet.key
              }
            });
          }
        }

        if (_ember['default'].isPresent(facets.directorate.buckets)) {
          for (i = 0; i < facets.directorate.buckets.length; i++) {
            facet = facets.directorate.buckets[i];

            results.push({
              "id": i + '-directorate',
              "type": "facet",
              "attributes": {
                "count": facet.doc_count,
                "f-type": "directorate",
                "term": facet.key
              }
            });
          }
        }

        if (_ember['default'].isPresent(facets.lesson_date.entries)) {
          for (i = 0; i < facets.lesson_date.entries.length; i++) {
            facet = facets.lesson_date.entries[i];

            var d = new Date(facet.time);

            results.push({
              "id": i + '-lesson_date',
              "type": "facet",
              "attributes": {
                "count": facet.count,
                "f-type": "lesson_date",
                "year": d.getFullYear(),
                "term": facet.time
              }
            });
          }
        }

        payload = {
          data: results
        };
      }

      //console.log(payload);
      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });
});