define('llis/models/facet', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _emberDataModel, _emberDataAttr) {
  exports['default'] = _emberDataModel['default'].extend({
    count: (0, _emberDataAttr['default'])(),
    term: (0, _emberDataAttr['default'])(),
    title: (0, _emberDataAttr['default'])(),
    fType: (0, _emberDataAttr['default'])(),
    qTerm: (0, _emberDataAttr['default'])(),
    year: (0, _emberDataAttr['default'])(),
    isSelected: (0, _emberDataAttr['default'])('boolean', { defaultValue: false })
  });
});