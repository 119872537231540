define('llis/routes/lesson/index', ['exports', 'ember', 'llis/mixins/reset-scroll'], function (exports, _ember, _llisMixinsResetScroll) {
  exports['default'] = _ember['default'].Route.extend(_llisMixinsResetScroll['default'], {
    //fastboot: Ember.inject.service(),

    model: function model(params) {
      //let host = this.get('fastboot.host');

      // console.log('[routes][lesson]:[model] ' + JSON.stringify(params));

      return this._getLesson('lesson', params.lesson_id);
    },

    setupController: function setupController(controller, model) {
      var self = this;
      this._super(controller, model);

      controller.set('relatedLoading', true);
      self.getMlt(model.get('id')).then(function (mlt) {
        model.set('mlt', mlt);
        model.set('likeThisFetched', true);
        controller.set('relatedLoading', false);
      });
    },

    _getRecord: function _getRecord(type, id) {
      var store = this.get('store'),
          record;

      record = store.peekRecord(type, id);

      if (_ember['default'].isPresent(record)) {
        return _ember['default'].RSVP.Promise.resolve(record);
      } else {
        return store.findRecord(type, id);
      }
    },

    _getLesson: function _getLesson(type, id) {
      var self = this;

      return self._getRecord(type, id).then(function (_record) {
        return _record;
      }, function (error) {
        throw new Error('Found user, but still unhappy. ' + JSON.stringify(error));
      }, 'getRecord');
    },

    getMlt: function getMlt(id) {
      var store = this.get('store'),
          self = this,
          record;

      record = store.peekRecord('lesson', id);

      if (_ember['default'].isPresent(record.get('likeThisFetched'))) {
        return _ember['default'].RSVP.Promise.resolve(record.get('mlt'));
      } else {
        return new _ember['default'].RSVP.Promise(function (resolve) {
          self.get('store').query('lesson', {
            "alt_query": {
              "query": {
                "more_like_this": {
                  "include": false,
                  "like": [{ "_index": "llis", "_id": id }]
                }
              }
            }
          }).then(function (mlt) {
            resolve(mlt);
          });
        });
      }
    },

    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('lesson.error');
        }
      }
    }

  });
});