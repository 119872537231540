define('llis/models/lesson', ['exports', 'ember-data/model', 'ember', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberDataModel, _ember, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({
    categories: (0, _emberDataAttr['default'])(), //categories == dc_subject
    attachments: (0, _emberDataAttr['default'])(),
    descriptionEvent: (0, _emberDataAttr['default'])(),
    drivingEvent: (0, _emberDataAttr['default'])(),
    evidence: (0, _emberDataAttr['default'])(),
    lesson: (0, _emberDataAttr['default'])(),
    lessonAbstract: (0, _emberDataAttr['default'])(),
    lessonDate: (0, _emberDataAttr['default'])(),
    lessonLearned: (0, _emberDataAttr['default'])(),
    missionDirectorate: (0, _emberDataAttr['default'])(),
    organization: (0, _emberDataAttr['default'])(),
    programPhase: (0, _emberDataAttr['default'])(),
    programRole: (0, _emberDataAttr['default'])(),
    programRelation: (0, _emberDataAttr['default'])(),
    recommendation: (0, _emberDataAttr['default'])(),
    title: (0, _emberDataAttr['default'])(),

    /* Polymorphic Workaround. Can't have many to many
       when you have mlt: hasMany('lesson'); the lessons start
       to steal the lessons from each other.
    */
    likeThisFetched: (0, _emberDataAttr['default'])('boolean'),
    likeThis: (0, _emberDataRelationships.hasMany)('mlt'),

    mlt: _ember['default'].computed('likeThis.@each', function () {
      this.get('likeThis').mapBy('lessonId');
    }),

    categoriesList: _ember['default'].computed('categories', function () {
      var categories = this.get('categories.name');

      if (_ember['default'].isPresent(categories) && typeof categories === 'string') {
        console.log(categories);
        return categories.split(', ').filter(String);
      } else if (_ember['default'].isPresent(categories) && typeof categories === 'object') {
        return categories;
      }
    }),

    missionList: _ember['default'].computed('missionDirectorate', function () {
      var directorate = this.get('missionDirectorate.name');
      if (_ember['default'].isPresent(directorate) && typeof directorate === 'string') {
        return directorate.split(', ').filter(String);
      } else if (_ember['default'].isPresent(directorate) && typeof directorate === 'object') {
        return directorate;
      }
    })

  });
});