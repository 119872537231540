define("llis/controllers/search", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    queryParams: ["page", "perPage", "query", "sort", "directorate", "categories", "organization", "lesson_date"],
    //queryParams: ["page", "perPage"],

    pageBinding: "content.page",
    perPageBinding: "perPage",
    totalPagesBinding: "content.totalPages",

    //page: 1,
    perPage: 30,
    pageSize: 30,
    laodHorizon: 50,

    end: _ember["default"].computed('page', 'perPage', 'batchSize', function () {
      var page = this.get('page'),
          perPage = this.get('perPage'),
          lastBatchSize = this.get('batchSize'),
          remain = perPage * (page - 1) + lastBatchSize;
      //console.log({page, total, perPage, lastBatchSize});

      return remain;
    }),

    index: _ember["default"].computed('page', 'perPage', function () {
      var page = this.get('page'),
          perPage = this.get('perPage');

      return (page - 1) * perPage + 1;
    }),

    //totalPages: Ember.computed('model.meta.total', function() {
    //  var total = this.get('model.meta.total'),
    //      perPage = Number(this.get('perPage')),
    //      maths = Math.ceil(total/perPage);

    //  //console.log({total, perPage, maths});
    //  return maths;
    //}),

    actions: {
      addFacet: function addFacet(obj) {
        var fType = obj.fType;
        var value = obj.value;

        this.set(fType, value);
        this.set('page', 1);
      }
    }

  });
});