define('llis/components/facet-list', ['exports', 'ember', 'llis/utils/facet-helper'], function (exports, _ember, _llisUtilsFacetHelper) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    aggs: _ember['default'].inject.service(),
    organization: '',
    categories: '',
    lesson_date: '',
    directorate: '',
    facets: null,

    onInit: (function () {
      //console.log('[component][facet-list]:[onInit]');
      var store = this.get('store'),
          self = this;

      var facets = this.get('facets');

      if (!_ember['default'].isPresent(facets)) {
        //this.set('facets', facets);
        // yeah. this is hacky. but it's the best way to do this without
        // shoving these into the store.
        store.query('lesson', {}).then(function (items) {
          self.set('facets', items.get('meta.aggs'));
          self.facetChanged();
        });
      } else {}
    }).on('init'),

    facetChanged: _ember['default'].observer('organization', 'directorate', 'categories', 'lesson_date', function () {
      _ember['default'].run.debounce(this, 'facetChangedDebounced', [], 150, true);
    }),

    facetChangedDebounced: function facetChangedDebounced() {
      var facets = this.get('facets'),
          self = this;

      if (_ember['default'].isEmpty(facets)) {
        return;
      }
      _ember['default'].A(['organization', 'directorate', 'categories', 'lesson_date']).map(function (type) {
        return self.get(type).split('+');
      }).reduce(function (a, b) {
        return a.concat(b);
      }).filter(_ember['default'].isPresent).map(function (item) {
        facets.filterBy('term', item).map(function (facet) {
          //facet.set('isSelected', true);
          _ember['default'].set(facet, 'isSelected', true);
        });
      });
    },

    items: (function () {
      var _facets = this.get('facets'),
          centers = this.get('centerMap'),
          organization = this.get('organization'),

      //subject = this.get('subject'),
      categories = this.get('categories'),
          lesson_date = this.get('lesson_date'),
          directorate = this.get('directorate');

      var types = [{
        'fType': 'organization',
        'multiSelect': 'false',
        'title': 'NASA Centers',
        'query': 'center',
        'weight': 0
      }, {
        'fType': 'categories',
        'multiSelect': 'true',
        'title': 'Topics',
        'query': 'categories',
        'weight': 0
      }, {
        'fType': 'directorate',
        'multiSelect': 'true',
        'title': 'Mission Directorates',
        'query': 'directorate',
        'weight': 0
      }, {
        'fType': 'lesson_date',
        'title': 'By Year',
        'query': 'year',
        'weight': 0
      }];

      if (_ember['default'].isEmpty(_facets) || !_ember['default'].isArray(_facets)) {
        return [];
      }

      for (var i = 0; i < types.length; i++) {
        types[i].facets = _facets.filterBy('fType', types[i].fType).map(function (f) {
          if (directorate.indexOf(f.term) !== -1 && f.fType === 'directorate') {
            f['isSelected'] = true;
          }
          if (lesson_date.indexOf(f.term) !== -1 && f.fType === 'lesson_date') {
            f['isSelected'] = true;
          }
          if (organization.indexOf(f.term) !== -1 && f.fType === 'organization') {
            f['isSelected'] = true;
          }
          if (categories.indexOf(f.term) !== -1 && f.fType === 'categories') {
            f['isSelected'] = true;
          }

          if (f.fType === "organization") {
            f['title'] = centers[f.term];
          } else {
            f['title'] = f.term;
          }
          f['short'] = types[i].short;

          return f;
        }).sortBy('title');
      }
      //console.log('[component][facet-list]:[items]: ');

      return types.sortBy('weight');
    }).property('facets', 'facets.isFulfilled'),

    centerMap: {
      "afrc": "Armstrong Flight Research Center",
      "arc": "Ames Research Center",
      "grc": "Glenn Research Center",
      "gsfc": "Goddard Space Flight Center",
      "hq": "NASA Headquarters",
      "ivv": "NASA IVV",
      "jpl": "Jet Propulsion Laboratory",
      "jsc": "Johnson Space Center",
      "ksc": "Kennedy Space Center",
      "larc": "Langley Research Center",
      "msfc": "Marshall Space Flight Center",
      "nesc": "NASA Engineering & Safety Center",
      "ssc": "Stennis Space Center",
      "wff": "Wallops Flight Facility",
      "wstf": "White Sands Test Facility"
    },

    actions: {
      setFacet: function setFacet(term) {
        _ember['default'].set(term, 'isSelected', !term.isSelected);
        //term['isSelected'] = !term['isSelected'];
        //term.set('isSelected', !term['isSelected']);

        var facets = this.get('facets').filterBy('fType', term['fType']).filterBy('isSelected', true).mapBy('term').join('+');

        this.sendAction('setFacet', { fType: term['fType'], value: facets });
      }
    }

  });
});